import Vue from 'vue';
import axios from 'axios';

const state = {
    disciplines: [],
    tags: [],
    offerList: [],
    projectDetails: {},
    featureFlags: {},
}

const mutations = {
    SET_DESCIPLINES (state, disciplines) {
        Vue.set(state, 'disciplines', disciplines);
    },
    SET_TAGS (state, tags) {
        Vue.set(state, 'tags', tags);
    },
    SET_OFFER_LSIT(state, offerList) {
        Vue.set(state, 'offerList', offerList);
    },
    SET_PROJECT_DETAILS (state, projectDetails) {
        state.projectDetails = projectDetails
    },
    SET_FEATURE_FLAGS(state, payload) {
        state.featureFlags = payload
    }
}

const actions = {
    getFeatureAuthToken({commit}) {
        axios({
            url: process.env.VUE_APP_API + '/flags',
            method: 'get'
        }).then((res) => {
            console.log('flags => ',res.data.data);
            commit('SET_FEATURE_FLAGS', res.data.data)
        })
    },
    async setDisciplines ({commit}, disciplineList) {
        commit('SET_DESCIPLINES', disciplineList);
    },
    async setTagList({commit}, mrId) {
        const tagList = await axios.get(`${process.env.VUE_APP_API}/mr/taglist?mr_id=${mrId}`);
        console.log('set tag list', tagList);
        commit('SET_TAGS', tagList.data.tags);
    },
    getVendorTimeline({commit}, param) {
        return axios.post(`${process.env.VUE_APP_API}/offer/vendor_timeline`, param);
    },
    getUnitsList() {
        return axios.get(`${process.env.VUE_APP_API}/tagged/units`);
    },
    async getCollaborationData({commit}, param) { 
        console.log(param);
        if (param.category_type != 'packages') {
            return await axios.get(`${process.env.VUE_APP_API_MSD}/quickaction?row_uuid=${param.row_uuid}&mr_id=${param.mr_id}&tag_id=${param.tag_id}&category=${param.category}`);
        } else {
            return await axios.get(`${process.env.VUE_APP_API_MSD}/quickaction?row_uuid=${param.row_uuid}&package_id=${param.package_id}&sub_package_id=${param.sub_package_uuid}&category=${param.category_type}`);
        }
    },
    uploadFile({commit}, item) {
        let data = new FormData();
        data.append('file', item.files)
        return axios.post(`${process.env.VUE_APP_API}/file/upload?${item.queryParam}`, data, {
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            processData: false,
            contentType: false,
        })
    },
    downloadFile({commit}, queryparams) {
        return axios.get(`${process.env.VUE_APP_API}/download/url?${queryparams}`);
    },
    downloadOffer({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/offer/download?${params}`);
    },
    downloadAllFiles ({commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/tqthread/attachments/export`, params);
    },
    downloadMultipleFiles({commit}, queryParam) {
        return axios.get(`${process.env.VUE_APP_API}${queryParam}`);
    },
    async setOfferList ({commit}, { id, query }) {
        console.log('setOfferList => ', id, query);
        let list = ''
        if (query?.package_id) {
            list = await axios.get(`${process.env.VUE_APP_API_MSD}/tqthread/vendors?package_id=${id}`);    
        } else {
            list = await axios.get(`${process.env.VUE_APP_API_MSD}/tqthread/vendors?mr_id=${id}`);
        }
        commit('SET_OFFER_LSIT', list.data.data);
    },
    clearProjectDetails({commit}){
        commit('SET_PROJECT_DETAILS', {});
    },
    async setProjectDetails ({ commit }, payload) {
        console.log('setProjectDetails',payload);
        let queryParams = ((payload.project_id)? `&project=${payload.project_id}`: '') + ((payload.mr_id)?`&item=${payload.mr_id}`: '')+ (payload.offer_id ? `&offer=${payload.offer_id}`: '') + (payload.package_id ? `&package_id=${payload.package_id}`: '')
        const projectDetails = await axios.get(`${process.env.VUE_APP_API}/header?page=${payload.page}${queryParams}`);
        commit('SET_PROJECT_DETAILS', projectDetails.data.data);
    },
    async getProjectUserList({commit}, projectId) {
        return await axios.get(`${process.env.VUE_APP_API}/project/manage?project=${projectId}`);
    },
    async getUserList({commit}, projectId) {
        return await axios.get(`${process.env.VUE_APP_API}/emails?project_id=${projectId}`);
    },
    notify({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/notify_users/internal`, payload);
    },
    getDocumentList({commit}, mrId) {
        if (mrId?.package_id) {
            return axios.get(`${process.env.VUE_APP_API}/mr/files?package_id=${mrId.package_id}&type=packages`);    
        } else {
            return axios.get(`${process.env.VUE_APP_API}/mr/files?id=${mrId}`);
        }
    },
    exportExtract({commit}, mrId) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/lhs-extract-download?mr_id=${mrId}`)
    },
    exportTBE({commit}, mrId) {
        return axios.get(`${process.env.VUE_APP_API}/mr/timeline/export?mr_id=${mrId}`)
    },
    trackTBE({commit}, id) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/job?job_id=${id}`)
    },
    async getVendorList() {
        return await axios.get(`${process.env.VUE_APP_API}/offer/vendors`);
    },
    async getCCEmails({commit}, projectId) {
        return await axios({
            url: process.env.VUE_APP_API + '/emails?project_id=' + projectId,
            method: 'get'
        });
    },
    async checkVendorName({commit}, payload) {
        return await axios({
            url: process.env.VUE_APP_API + '/offer/vendor_check?vendor_name=' + payload.venDel + '&check=vendor_name&item=' + payload.item_id,
            method: 'get'
        });
    },
    downloadTQAttachments({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/tqthread/attachments/export`, payload);
    },
    selectOtherContext ({ state }, param) {
        console.log('param => ', param);
        if (param?.category) {
            if (param.category == 'packages') {
                return axios.put(`${process.env.VUE_APP_API}/package/offer/multiple_context`, param)
            } else {
                return axios.put(`${process.env.VUE_APP_API}/offer/cycle`, param);
            }
        }
    },
    getCollaborationStats({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/collaboration/stats`, payload);
    },
    exportTimeline({commit}, mrId) {
        return axios.get(`${process.env.VUE_APP_API}/mr/timeline/export?mr_id=${mrId}`)
    },
    downloadJob({commit}, id) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/job?job_id=${id}`)
    },
    downloadLHSExtract({commit}, mrId) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/lhs-extract-download?mr_id=${mrId}`)
    },
    lockTBE ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSC}/tbe/lock`, params);
    },
    unLockTBE ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSC}/tbe/unlock`, params);
    },
    getVendorListNames ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API}/offer/names?${params}`);
    },
    getValidateEmails ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API}/validate/emails`, params);
    },
    publishedVendorEmails ({ state }, params) {
        const baseUrl = `${process.env.VUE_APP_API}/current/emails?token=${params.token}&vendor_id=${params.vendor_id}&current_email=${params.current_email}`;
        const additionalParam = params.mr_id
            ? `&mr_id=${params.mr_id}`
            : `&package_id=${params.package_id}`;
        const finalUrl = `${baseUrl}${additionalParam}`;
        return axios.get(finalUrl);
    },
}

const getters = {
    getDisciplines () {
        return state.disciplines;
    },
    getTagList () {
        return state.tags;
    },
    getOfferList() {
        return state.offerList;
    },
    getProjectDetails () {
        return state.projectDetails
    },
    getFeatureFlags() {
        return state.featureFlags
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}